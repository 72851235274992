// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trashbin-container{
    display: flex;
    flex-direction: row;
    width: 70vw;
    flex-wrap: wrap;
}
.trashbin-card{
    padding: 1vh;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.trashbin-name{
    font-size: 10px;
    margin: 0px;
}
.trashbin-delete-button, .trashbin-replace-button{
    font-size: 5px;
    border: none;
    background-color: red;
    color: white;
    border-radius: 2px;
    margin-right: 5px;
    padding: 5px;
}
.trashbin-replace-button{
    background-color: green;
}
.trashbin-main-container{
    display: flex;
    flex-direction: column;
    padding: 2vw;
}
.trashbin-allclear-button{
    align-self: flex-end;
    font-size: 1vw;
    border: none;
    border-radius: 5px;
    padding: 1vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/TrashBin/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,eAAe;AACnB;AACA;IACI,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,cAAc;IACd,YAAY;IACZ,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;AAChB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;AACA;IACI,oBAAoB;IACpB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".trashbin-container{\n    display: flex;\n    flex-direction: row;\n    width: 70vw;\n    flex-wrap: wrap;\n}\n.trashbin-card{\n    padding: 1vh;\n    border: 1px solid black;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 5px;\n    border-radius: 5px;\n    margin-right: 10px;\n    margin-bottom: 10px;\n}\n.trashbin-name{\n    font-size: 10px;\n    margin: 0px;\n}\n.trashbin-delete-button, .trashbin-replace-button{\n    font-size: 5px;\n    border: none;\n    background-color: red;\n    color: white;\n    border-radius: 2px;\n    margin-right: 5px;\n    padding: 5px;\n}\n.trashbin-replace-button{\n    background-color: green;\n}\n.trashbin-main-container{\n    display: flex;\n    flex-direction: column;\n    padding: 2vw;\n}\n.trashbin-allclear-button{\n    align-self: flex-end;\n    font-size: 1vw;\n    border: none;\n    border-radius: 5px;\n    padding: 1vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
