// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.truck-animation {
    animation: moveTruck 3s linear forwards;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  @keyframes moveTruck {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  .delete-truck{
    width: 7vw;
    height: 8vh;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Demo/index.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,uCAAuC;IACvC,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE;MACE,4BAA4B;IAC9B;IACA;MACE,2BAA2B;IAC7B;EACF;EACA;IACE,UAAU;IACV,WAAW;EACb","sourcesContent":["/* styles.css */\n.truck-animation {\n    animation: moveTruck 3s linear forwards;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  @keyframes moveTruck {\n    0% {\n      transform: translateX(-100%);\n    }\n    100% {\n      transform: translateX(100%);\n    }\n  }\n  .delete-truck{\n    width: 7vw;\n    height: 8vh;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
